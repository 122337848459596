.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  background: #193869;
  color: white;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 40px!important;
}

h1 {
  text-transform: uppercase;
  font-weight: bold;
  color: #eaeaea;
  font-size: 1.6em;
  font-family: 'Questrial', sans-serif;
  letter-spacing: 3px;
}

h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 3.5em;
  font-weight: bold;
  margin-top: 1.5em;
  letter-spacing: 2px;
  margin-bottom: 0;
}

h3 {
  font-family: 'Questrial', sans-serif;
  margin-top: 2em;
  font-size: 1.7em;
  border-top: solid 1px #817d7d;
  padding-top: 2em;
}

p {
  font-family: 'Questrial', sans-serif;
  font-weight: lighter;
  font-size: 1.5em;
  color: #cecece;
}

p.small {
  font-size: 1.3em;
}

a {
  text-decoration: underline;
  color: inherit;
}
footer {
  width: 100%;
  font-family: 'Questrial', sans-serif;
  color: whitesmoke;
  float: left;
  margin-top: 10em;
}

.appicons {
  margin-top: 1em;
  width: 100%;
}
.appicon {
  float: left;
  height: 80px;
  margin-right: 30px;
  width: 80px;
  border-radius: 20px;
  border: 5px dashed white;
  background: #ffffff14;
  opacity: .5;
}

b {
  font-weight: bold!important;
}